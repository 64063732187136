.confetti-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1000; /* Set a high z-index to ensure it appears on top of other elements */
  }

  .winnerRow {
    margin: 10px;
  }

  .winnerName {
    font-size: 1.5em;
  }

  .winnerTitle {
    font-size: 2em;
    color: cyan;
  }